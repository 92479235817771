exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-beschikbaar-tsx": () => import("./../../../src/pages/beschikbaar.tsx" /* webpackChunkName: "component---src-pages-beschikbaar-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-extra-tsx": () => import("./../../../src/pages/extra.tsx" /* webpackChunkName: "component---src-pages-extra-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-locatie-tsx": () => import("./../../../src/pages/locatie.tsx" /* webpackChunkName: "component---src-pages-locatie-tsx" */),
  "component---src-pages-situatie-tsx": () => import("./../../../src/pages/situatie.tsx" /* webpackChunkName: "component---src-pages-situatie-tsx" */),
  "component---src-pages-woningen-blok-1-en-5-tsx": () => import("./../../../src/pages/woningen/blok1-en-5.tsx" /* webpackChunkName: "component---src-pages-woningen-blok-1-en-5-tsx" */),
  "component---src-pages-woningen-blok-2-en-3-tsx": () => import("./../../../src/pages/woningen/blok2-en-3.tsx" /* webpackChunkName: "component---src-pages-woningen-blok-2-en-3-tsx" */),
  "component---src-pages-woningen-blok-4-en-6-tsx": () => import("./../../../src/pages/woningen/blok4-en-6.tsx" /* webpackChunkName: "component---src-pages-woningen-blok-4-en-6-tsx" */),
  "component---src-pages-woningen-tsx": () => import("./../../../src/pages/woningen.tsx" /* webpackChunkName: "component---src-pages-woningen-tsx" */)
}

