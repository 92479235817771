import React from 'react'
import {graphql, Link, useStaticQuery} from "gatsby";
import Toolbar from "@mui/material/Toolbar";
import {Button, IconButton } from "gatsby-material-ui-components";
import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {Menu, useTheme} from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';
import {StaticImage} from "gatsby-plugin-image";

const navbarHeight = 96;
const logoHeight = 234;

interface MenuItemProps {
    url: string
    title: string
    color?: 'secondary'
    handleClick: () => void
}

const MenuButton = ({url, title, color, handleClick}: MenuItemProps) => {
    return (
        <Button
            to={url}
            color={color}
            onClick={handleClick}
            sx={{ my: 2, display: 'block' }}
        >
            {title}
        </Button>
    )
}

const MenuLink = ({url, title, color, handleClick}: MenuItemProps) => {
    return (
        <MenuItem
            component={Link}
            to={url}
            key={title}
            color={color}
            onClick={handleClick}
        >
            <Typography textAlign="center">{title}</Typography>
        </MenuItem>
    )
}

interface HeaderProps {
    sections: ReadonlyArray<{
        title: string;
        url: string;
    }>;
    title: string;
}

const Header = () => {
    const data = useStaticQuery(graphql`
        query Brochure {
            media(titel: {eq: "Brochure"}) {
                localFile {
                    publicURL
                }
            }
        }
    `)

    const theme = useTheme();
    const desktop = useMediaQuery(theme.breakpoints.up('sm'));

    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    return (
        <>
            <AppBar
                position="static"
                color="default"
                elevation={0}
                sx={{
                    backgroundColor: 'common.white'
                }}
            >
                <Container>
                    <Toolbar
                        disableGutters
                        component="nav"
                        variant="regular"
                    >
                        <Link to={'/'}>
                            {desktop ?
                                <StaticImage
                                    alt={'logo'}
                                    src={'../../images/logo.png'}
                                    height={logoHeight}
                                    style={{
                                        marginBottom: navbarHeight-logoHeight,
                                        zIndex: 1
                                    }}
                                />
                                :
                                <StaticImage
                                    alt={'logo'}
                                    src={'../../images/logo.png'}
                                    height={logoHeight/3}
                                    style={{
                                        marginBottom: navbarHeight-logoHeight,
                                        zIndex: 1
                                    }}
                                />
                            }
                        </Link>
                        <Box
                            sx={{
                                flexGrow: 1,
                                display: { xs: 'flex', md: 'none' }
                            }}
                            justifyContent={'flex-end'}
                        >
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: { xs: 'block', md: 'none' },
                                }}
                            >
                                <MenuLink url={'/locatie'} title={'Locatie'} handleClick={handleCloseNavMenu} />
                                <MenuLink url={'/situatie'} title={'Situatie'} handleClick={handleCloseNavMenu} />
                                <MenuLink url={'/woningen'} title={'Woningen'} handleClick={handleCloseNavMenu} />
                                <MenuLink url={'/beschikbaar'} title={'Beschikbaar'} handleClick={handleCloseNavMenu} />
                                <MenuLink url={'/extra'} title={'Extra'} handleClick={handleCloseNavMenu} color={'secondary'} />
                                <MenuItem
                                    href={data.media.localFile.publicURL}
                                    color={'secondary'}
                                    onClick={handleCloseNavMenu}
                                >
                                    Brochure
                                </MenuItem>
                                <MenuLink url={'/contact'} title={'Contact'} handleClick={handleCloseNavMenu} color={'secondary'} />
                            </Menu>
                        </Box>
                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                            <MenuButton url={'/locatie'} title={'Locatie'} handleClick={handleCloseNavMenu} />
                            <MenuButton url={'/situatie'} title={'Situatie'} handleClick={handleCloseNavMenu} />
                            <MenuButton url={'/woningen'} title={'Woningen'} handleClick={handleCloseNavMenu} />
                            <MenuButton url={'/beschikbaar'} title={'Beschikbaar'} handleClick={handleCloseNavMenu} />
                        </Box>
                        <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
                            <MenuButton url={'/extra'} title={'Extra'} handleClick={handleCloseNavMenu} color={'secondary'} />
                            <Button
                                href={data.media.localFile.publicURL}
                                color={'secondary'}
                                onClick={handleCloseNavMenu}
                                sx={{ my: 2, display: 'block' }}
                            >
                                Brochure
                            </Button>
                            <MenuButton url={'/contact'} title={'Contact'} handleClick={handleCloseNavMenu} color={'secondary'} />
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
        </>
    )
}

export default Header