import { createTheme, responsiveFontSizes } from "@mui/material";

export const sectionPadding = 5;
export const sectionGridSpacing = 5;
export const headerMargin = 10;
export const textMaxWidth = 768;

const theme = createTheme({
    components: {
        MuiContainer: {
            defaultProps: {
                maxWidth: 'lg'
            }
        },
        MuiTextField: {
            defaultProps: {
                variant: 'filled',
            }
        },
        MuiButton: {
            defaultProps: {
                sx: {
                    fontWeight: '800'
                }
            }
        },
    },
    typography: {
        fontFamily: [
            'Archivo',
            'Arial'
        ].join(','),
        h2: {
            fontFamily: 'Rajdhani',
            fontSize: '2.813rem',
            textTransform: 'uppercase',
            fontWeight: 'bold',
            color: '#0D543D',
            lineHeight: 1,
            marginBottom: 24
        },
        h3: {
            fontFamily: 'Rajdhani',
            fontSize: '2rem',
            textTransform: 'uppercase',
            fontWeight: 'bold',
            color: '#B49656'
        },
        h6: {
            textTransform: 'uppercase',
            color: '#B49656',
            fontWeight: 'bold',
            fontSize: '1rem',
        }
    },
    palette: {
        primary: {
            main: '#0D543D'
        },
        secondary: {
            main: '#B49656',
            light: '#F7F4EE'
        },
        divider: '#B49656',
        text: {
            primary: '#000000'
        }
    },

});

export default responsiveFontSizes(theme);