import React, {ReactNode} from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import {Theme, ThemeProvider} from '@mui/material/styles';
import Viewport from './viewport';
import Navbar from "./navbar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import {StaticImage} from "gatsby-plugin-image";
import Typography from "@mui/material/Typography";
import {Alert, Grid, IconButton, Link} from "@mui/material";
import {sectionPadding} from "../../theme";
import {Instagram, LinkedIn} from "@mui/icons-material";

interface Props {
    children: ReactNode
    theme: Theme
}
export default function Index({ children, theme }: Props) {
    return (
        <>
            <Viewport />
            <ThemeProvider theme={theme}>
                <Alert severity="error" sx={{position: 'static', top: 0, textAlign: 'center', justifyContent: 'center'}}>Alle woningen zijn verkocht!</Alert>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minHeight: '100vh',
                    }}
                >
                    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                    <CssBaseline />
                    <Navbar />
                    <Box>
                        {children}
                    </Box>
                    <Box
                        component={'footer'}
                        sx={{
                            backgroundColor: theme => theme.palette.primary.main,
                            mt: 'auto',
                        }}
                        paddingY={sectionPadding}
                        color={'common.white'}
                    >
                        <Container
                            maxWidth={'lg'}
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}
                        >
                            <Grid
                                container
                                sx={{
                                    justifyContent: {xs: 'center', md: 'left'}
                                }}
                            >
                                <Grid item xs={12} md={6}>
                                    <Grid container>
                                        <Grid item xs={12} sm={6}>
                                            <StaticImage
                                                alt={'header'}
                                                src={'../../images/logos/de-realisatie.png'}
                                                height={50}
                                                style={{
                                                    marginBottom: 16
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <StaticImage
                                                alt={'header'}
                                                src={'../../images/logos/dna-wit.png'}
                                                height={50}
                                                style={{
                                                    marginBottom: 16
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <Box
                                                fontSize={12}
                                            >
                                                <Typography
                                                    fontSize={'inherit'}
                                                    marginBottom={1}
                                                >
                                                    Ontwikkelaar:
                                                </Typography>
                                                <Typography
                                                    fontWeight={'bold'}
                                                    textTransform={'uppercase'}
                                                    fontSize={'inherit'}
                                                >
                                                    De Realisatie
                                                </Typography>
                                                <address>
                                                    <Link
                                                        href={'tel:0858771293'}
                                                        color={'common.white'}
                                                    >
                                                        085 877 1293
                                                    </Link><br />
                                                    <Link
                                                        href={'mailto:info@de-realisatie.nl'}
                                                        color={'common.white'}
                                                    >
                                                        info@de-realisatie.nl
                                                    </Link><br />
                                                    <Link
                                                        href={'https://www.de-realisatie.nl'}
                                                        target={'_blank'}
                                                        color={'common.white'}
                                                    >
                                                        www.de-realisatie.nl
                                                    </Link>
                                                </address>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <Box
                                                fontSize={12}
                                            >
                                                <Typography
                                                    fontSize={'inherit'}
                                                    marginBottom={1}
                                                >
                                                    Verkoop:
                                                </Typography>
                                                <Typography
                                                    fontWeight={'bold'}
                                                    textTransform={'uppercase'}
                                                    fontSize={'inherit'}
                                                >
                                                   DNA Makelaars
                                                </Typography>
                                                <address>
                                                    Antennestraat 86<br />
                                                    1322 AS Almere <br/>
                                                    <Link
                                                        href={'tel:0367370591'}
                                                        color={'common.white'}
                                                    >
                                                        036 737 05 91
                                                    </Link><br />
                                                    <Link
                                                        href={'mailto:info@dna-makelaars.nl'}
                                                        color={'common.white'}
                                                    >
                                                        info@dna-makelaars.nl
                                                    </Link><br />
                                                    <Link
                                                        href={'https://www.dna-makelaars.nl'}
                                                        target={'_blank'}
                                                        color={'common.white'}
                                                    >
                                                        www.dna-makelaars.nl
                                                    </Link>
                                                </address>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <Typography
                                                fontWeight={'bold'}
                                                textTransform={'uppercase'}
                                            >
                                               Volg ons
                                            </Typography>
                                            <IconButton
                                                href={'https://www.instagram.com/dna.makelaars/'}
                                                target={"_blank"}
                                                color={'inherit'}
                                            >
                                                <Instagram/>
                                            </IconButton>
                                            <IconButton
                                                disabled
                                                color={'inherit'}
                                            >
                                                <LinkedIn/>
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Container>
                    </Box>
                </Box>
            </ThemeProvider>
        </>
    );
}